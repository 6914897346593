import React from 'react';

import Layout from './components/layout';
// ** import styles and fonts
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
    applicationId: '83fa52f6-ecb2-4073-8050-de86ee9a2116',
    clientToken: 'pub72e9eba3d01555fb97276f173e9480b0',
    site: 'datadoghq.com',
    service:'verifiinspect-partner-form',
    env:'production',
    // Specify a version number to identify the deployed version of your application in Datadog 
    version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel:'allow'
});
    
datadogRum.startSessionReplayRecording();
    
function PartnerExpressForm() {

datadogRum.startSessionReplayRecording();    
  
  return (
    <Layout />
  );
}

export default PartnerExpressForm;
